import { useFormikContext } from "formik";
import { useI18n } from "../../src/ui/hooks/useI18n";
import { Asserts } from "yup";
import { YupHealthRecordSchema } from "../../src/ui/pages/health_record/HealthRecordForm";
import { forwardRef } from "react";
import { StyledInput } from "../../src/ui/components/mui/StyledInput";
import ReactInputMask from "react-input-mask-format";
import { SecurityNumberInputProps } from "../../src/appConfig";

export function SecurityNumberInput({ isReadonly, translateErrors }: SecurityNumberInputProps) {
  const { t } = useI18n();
  const { values, getFieldProps, errors, touched } = useFormikContext<Asserts<YupHealthRecordSchema>>();

  return (
    <>
      <StyledInput
        {...getFieldProps("externalId")}
        fullWidth
        label={t("common.user_infos.social_security_number")}
        placeholder="ex : 121282-88882"
        inputComponent={!isReadonly ? (SocialSecurityMaskInput as any) : undefined}
        readOnly={isReadonly}
        error={!!errors.externalId}
        errorMessage={errors.externalId}
        touched={touched.externalId}
        translateErrors={translateErrors}
        value={values.externalId}
      />
    </>
  );
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  value: string;
}

const SocialSecurityMaskInput = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  return (
    <ReactInputMask
      {...props}
      mask="999999-99999"
      onChange={props.onChange}
      value={props.value}
      ref={ref as any}
    />
  );
});